// @flow

import * as React from 'react'
import GetInTouch from '../GetInTouch'
import {
  ProtocolsTableAlt,
  TitledMessage,
  Button,
  ContentThird,
  ContentTwoThirds,
  PD_COMPARE_DATA,
} from '../../ui-components'

import PD_IMAGE from './assets/image-pd-large.png'
import CHROME_LOGO from './assets/logo-chrome.svg'

import * as styles from './ProtocolDesigner.module.css'

export const MESSAGES = [
  {
    title: 'Liquids and labware',
    msg: 'Choose from a library of common labware. Define and track your reagents and samples.',
  },
  {
    title: 'Simple liquid handling',
    msg: 'Build complex protocols from simple liquid handling commands.',
  },
  {
    title: 'Advanced pipetting controls',
    msg: 'Fine-tune your OT-2 performance with control of pipetting speed and technique',
  },
]

const pdButtonProps = [
  {
    children: 'try the protocol designer',
    linkOutProps: {
      url: 'https://designer.opentrons.com/',
      gtm: {
        action: 'click',
        category: 'b-designer',
        label: 'beta',
      },
    },
    Component: 'a',
    white: true,
    className: styles.git_button,
  },
]

export default function ProtocolsDesigner() {
  const pdLinkOutProps = {
    url: 'https://designer.opentrons.com/',
    gtm: {
      action: 'click',
      category: 'b-designer',
      label: 'beta',
    },
  }
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.h2}>Protocol Designer</h2>
          <span className={styles.beta}>beta</span>
        </div>
        <h5 className={styles.h5}>
          Generate basic protocols for the OT-2 without code
        </h5>
        <div className={styles.content}>
          <ContentTwoThirds>
            <img className={styles.pd_image} src={PD_IMAGE} alt="" />
          </ContentTwoThirds>
          <ContentThird className={styles.content_third}>
            {MESSAGES.map((message, i) => {
              return (
                <TitledMessage
                  key={i}
                  title={message.title}
                  msg={message.msg}
                />
              )
            })}
            <Button
              className={styles.button}
              linkOutProps={pdLinkOutProps}
              Component="a"
            >
              open protocol designer
            </Button>
          </ContentThird>
        </div>
        <div className={styles.content}>
          <ContentThird className={styles.protocol_content_third}>
            <h3 className={styles.h3}>
              Is the Protocol Designer right for me?
            </h3>
            <div className={styles.pd_description}>
              <p className={styles.p}>
                The Protocol Designer is the easiest way to start writing your
                own basic protocols.
              </p>
              <br />
              <p className={styles.p}>
                With its point-and-click interface, you can quickly create
                dilutions, plate mappings, and other common protocols -- no
                coding required.
              </p>
              <br />
              <p className={styles.p}>
                We recommend most new users start here, and then explore the
                Opentrons API when looking for advanced and custom functionality
                such as CSV mapping and embedded conditional logic.
              </p>
            </div>
            <div className={styles.chrome_note}>
              <div>
                <img
                  className={styles.google_chrome_logo}
                  src={CHROME_LOGO}
                  alt=""
                />
              </div>
              <div className={styles.chrome_description}>
                <span className={styles.semibold}>Please note:</span> <br />
                <div>
                  At this time Protocol Designer must be accessed using Chrome
                  Browser to function correctly.
                </div>
              </div>
            </div>
          </ContentThird>
          <ContentTwoThirds>
            <ProtocolsTableAlt data={PD_COMPARE_DATA} />
          </ContentTwoThirds>
        </div>
      </div>
      <GetInTouch
        title="Start designing now"
        buttons={pdButtonProps}
        centeredContent={true}
        gtmCategory={'cta-3'}
        gtmLabelDemo={'schedule-demo'}
        gtmLabelEmail={'email-us'}
        gtmLabelChat={'chat-with-us'}
      />
    </>
  )
}
