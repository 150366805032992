import React from 'react'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import ProtocolDesigner from '../../components/ProtocolDesigner'

const ProtocolDesignerPage = () => (
  <Page>
    <SEO
      title="Opentrons Protocol Designer | Build New Protocols, Code-Free"
      description="The Protocol Designer is the easiest way to start writing basic protocols for automation. With its point-and-click interface, you can quickly create dilutions, plate, distribute, and much more."
    />
    <ProtocolDesigner />
  </Page>
)

export default ProtocolDesignerPage
